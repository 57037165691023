import React, { useCallback } from "react";
import PreviewLevel from "../previewParts/PreviewLevel";
import PreviewLine from "../previewParts/PreviewLine";
import PreviewModule from "../previewParts/PreviewModule";
import PreviewText from "../previewParts/PreviewText";
import DesignItemPreviewScrollbar from "./DesignItemPreviewScrollbar";
import PreviewColumnsHolder from "../previewParts/PreviewColumnsHolder";
import PreviewColumn from "../previewParts/PreviewColumn";

const DesignItemPreview = ({designItem}) => {

  return ( 
    <DesignItemPreviewScrollbar>
     { designItem
      ?
        designItem.typeOfContent === 'module' &&
        <PreviewModule part = {designItem}/>
        ||
        designItem.typeOfContent === 'level1' &&
        <PreviewLevel part = {designItem} parentStyle = {{}}/>
        ||
        designItem.typeOfContent === 'columns_holder' &&
        <PreviewColumnsHolder part = {designItem} parentStyle = {{}}/>
        ||
        designItem.typeOfContent === 'column' &&
        <PreviewColumn part = {designItem} parentStyle = {{}}/>
        ||
        (designItem.typeOfContent === 'heading' || designItem.typeOfContent === 'subheading' || designItem.typeOfContent === 'text_field') 
        &&
        <PreviewText part = {designItem} parentStyle = {module.style}/>
        ||
        designItem.typeOfContent === 'line' &&
        <PreviewLine part = {designItem} parentStyle = {{}}/>
      :
      <></>
      }
    </DesignItemPreviewScrollbar>
   );
}
 
export default DesignItemPreview;
import { useEffect} from "react";
import { useState } from "react";

const heading_style = {
  overflow: 'hidden',
  position: 'relative',
}

const PreviewText = ({part}) => {

  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...heading_style, ...part.style, width: '90%'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...heading_style, ...part.style, width: '90%'}))
  }, [part.style])


  return (
    <div style={style} className="textarea-component-hover">
      <div style={{ width: '100%', height: '100%'}}>
        {part.content}
      </div>
    </div>
  )
}

export default PreviewText
import { useEffect} from "react";
import { useState } from "react";

const PreviewLine = ({part}) => {

  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...part.style, width: '100%', height: 'fit-content', position: 'relative',}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style,width: '100%', height: 'fit-content', position: 'relative',}))
  }, [part.style])

  return (
    <div style={style}/>
  )
}

export default PreviewLine